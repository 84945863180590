<template>
  <section>
    <div class="card">
      <div class="card-body">
        <form @submit.prevent="filterSubscription">
          <div class="row">
            <div class="col-lg-1 col-md-6">
              <app-input v-model="filter.id" id="filter_id" :label="$t('eshopSubscription.id')"></app-input>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-input v-model="filter.code" id="filter_order" :label="$t('eshopSubscription.code')"></app-input>
            </div>
            <div class="col-lg-2">
              <app-select
                v-model="filter.type"
                :options="subscriptionTypeValues"
                :label="$t('eshopSubscription.type')"
                track-by="id"
                id="filter_type"
              >
              </app-select>
            </div>
            <div class="col-lg-1">
              <app-select
                v-model="filter.activated"
                :options="enabledTypeValues"
                :label="$t('eshopSubscription.activated')"
                id="filter_activated"
                noEmptyValue
              >
              </app-select>
            </div>
            <div class="col-lg-1">
              <app-select
                v-model="filter.setting.marketing"
                :options="enabledTypeValues"
                :label="$t('eshopSubscription.setting.marketing')"
                id="filter_setting_marketing"
                noEmptyValue
              >
              </app-select>
            </div>
            <div class="col-lg-1">
              <app-select
                v-model="filter.setting.admin"
                :options="enabledTypeValues"
                :label="$t('eshopSubscription.setting.admin')"
                id="filter_setting_admin"
                noEmptyValue
              >
              </app-select>
            </div>
            <div class="col-lg-2">
              <label>{{ $t('eshopSubscription.digitalServices') }}</label><br>
              <app-multi-select
                v-model="filter.digitalServices"
                :options="digitalServices"
                label="name"
                track-by="id"
                id="filter_digitalServices"
              >
              </app-multi-select>
            </div>
            <div class="col-lg-2">
              <button class="btn btn-success pull-right m-t-30">
                {{ $t('eshopSubscription.filter.search') }}
              </button>
              <button
                v-if="isExportButtonVisible"
                class="btn btn-success pull-right m-t-30"
                @click.prevent="exportSubscriptions"
              >
                {{ $t('eshopSubscription.filter.export') }}
              </button>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-datepicker
                type="date"
                v-model="filter.createdAtFrom"
                id="filter_createdAtFrom"
                :label="$t('eshopSubscription.created_at_from')"
                disable-form-group
              >
              </app-datepicker>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-datepicker
                type="date"
                v-model="filter.createdAtTo"
                id="filter_createdAtTo"
                :label="$t('eshopSubscription.created_at_to')"
                disable-form-group
              >
              </app-datepicker>
            </div>
            <div class="col-lg-6">
            </div>
            <div class="col-lg-2">
              <button class="btn btn-success pull-right m-t-30" data-test="subscription_reset_filter"
                      @click.prevent="resetFilters">
                {{ $t('eshopSubscription.filter.reset_filter') }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>
<script>
import Input from '../form/inputs/Input'
import Select from '../form/select/Select'
import SubscriptionFilterModel from '../../model/eshop/SubscriptionFilter'
import Datepicker from '../form/Datepicker'
import SubscriptionTypeMixin from '../mixins/valueObject/eshop/SubscriptionTypeMixin'
import EnabledTypeMixin from '../mixins/valueObject/eshop/EnabledTypeMixin'
import MultiSelect from '../form/select/MultiSelect'
import DownloadFileService from '../../services/eshop/DownloadFileService'
import NotifyService from '../../services/NotifyService'
import PermissionService from '@/services/PermissionService'

export default {
  name: 'SubscriptionFilter',
  mixins: [SubscriptionTypeMixin, EnabledTypeMixin],
  data () {
    return {
      isLoading: false,
      filter: this._.cloneDeep(SubscriptionFilterModel),
      advancedFilter: false
    }
  },
  components: {
    appInput: Input,
    appSelect: Select,
    appDatepicker: Datepicker,
    appMultiSelect: MultiSelect
  },
  computed: {
    isExportButtonVisible () {
      const permission = PermissionService.REQUIRED_PERMISSIONS.ESHOP_SUBSCRIPTION_PERMISSIONS.exportButton
      return this.$store.getters['user/hasPermission'](permission)
    },
    digitalServices () {
      return this.$store.getters['eshopDigitalService/all']
    }
  },
  methods: {
    filterSubscription () {
      this.$store.commit('eshopSubscription/setPage', 1)
      this.$store.commit('eshopSubscription/setFilter', this.filter)
      this.$store.dispatch('eshopSubscription/fetch')
    },
    resetFilters () {
      this.filter = this._.cloneDeep(SubscriptionFilterModel)
      this.filterSubscription()
    },
    exportSubscriptions () {
      this.$store.dispatch('eshopSubscription/export')
        .then((res) => {
          DownloadFileService.download(res, 'predplatne_', '.csv')
        })
        .catch(error => {
          console.log(error)
          NotifyService.setErrorMessage(this.$t('eshop.notify.export_failed') + ' (' + error + ')')
        })
    }
  },
  beforeMount () {
    this.filter = this.$store.getters['eshopSubscription/filter']
  }
}
</script>

<style lang="scss">

</style>
