<template>
    <span>
        <button
          type="button"
          class="btn btn-success"
          :title="$t('eshopSubscription.button.importTrend')"
          @click="showModal"
        >
            <span class="hidden-sm-down">{{ $t('eshopSubscription.button.importTrend') }}</span>
        </button>

        <app-modal
          v-if="modal"
          modal-size="modal-lg"
          :title="$t('eshopSubscription.button.importTrend')"
          @close="closeModal"
        >
            <!-- Body -->
            <template slot="body">
                <input class="btn btn-success m-b-20" type="file" id="input" @change="importCsv">
                <div class="table-wrapper-scroll-y table-scrollbar">
                    <table class="table table-striped table-bordered">
                        <thead>
                            <tr>
                                <th></th>
                                <th>{{ $t('eshopSubscription.importTrend.datvys') }}</th>
                                <th>{{ $t('eshopSubscription.importTrend.cisfak') }}</th>
                                <th>{{ $t('eshopSubscription.importTrend.cisdo') }}</th>
                                <th>{{ $t('eshopSubscription.importTrend.pocet') }}</th>
                                <th>{{ $t('eshopSubscription.importTrend.prij') }}</th>
                                <th>{{ $t('eshopSubscription.importTrend.dateTo') }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(subscription, index) in subscriptions" :key="`item-${index}`">
                                <td>{{ index + 1 }}.</td>
                                <td>{{ subscription.raw ? subscription.raw.invoiceDate : '' }}</td>
                                <td><b>{{ subscription.code }}</b></td>
                                <td>{{ subscription.raw ? subscription.raw.issueUntil : '' }}</td>
                                <td><b>{{ subscription.accounts }}</b></td>
                                <td>{{ subscription.raw ? subscription.raw.company : '' }}</td>
                                <td>
                                    <span v-show="subscription.activeUntil"><b>{{
                                        subscription.activeUntil | prettyDateTime
                                      }}</b></span>
                                    <span v-show="subscription.activeUntil === ''"></span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </template>

          <!-- Sub header -->
            <template slot="sub-header">
                <app-preloader v-if="callingAPI"></app-preloader>
                <button
                  type="button"
                  class="btn btn-inverse router-link-active"
                  @click="closeModal"
                >
                    {{ $t('eshopSubscription.button.cancel') }}
                </button>
                <button
                  type="button"
                  class="btn btn-danger"
                  @click="importSubscriptions"
                >
                    {{ $t('eshopSubscription.button.confirm') }}
                </button>
            </template>
        </app-modal>
    </span>
</template>

<script>
import Modal from '../shared/Modal'
import _ from 'lodash'
import Subscription from '../../model/eshop/Subscription'
import { dateTimeNow } from '../../services/DateTimeService'
import moment from 'moment'
import NotifyService from '../../services/NotifyService'
import Preloader from '../preloader/Preloader'
import { mapState } from 'vuex'

export default {
  name: 'ButtonImportSubscription',
  computed: {
    ...mapState([
      'callingAPI'
    ])
  },
  data () {
    return {
      modal: false,
      subscriptions: []
    }
  },
  components: {
    appModal: Modal,
    appPreloader: Preloader
  },
  methods: {
    showModal () {
      this.modal = true
    },
    closeModal () {
      this.modal = false
      this.subscriptions = []
      this.$emit('closeModal')
    },
    filterInvalidRows (data) {
      return data
        .filter(row => {
          if (row.invoiceNumber !== '' && row.issueUntil !== '' && row.accounts !== '') {
            return row
          } else {
            NotifyService.setErrorMessage(`Riadok neobsahuje vsetky povinne stlpce (CISFAK, CISDO, POCET): ${JSON.stringify(row)}`)
            console.log(row)
          }
        })
    },
    mapCsvToSubscription (parsedCsv) {
      return parsedCsv
        .map(row => {
          const subscription = _.cloneDeep(Subscription)
          subscription.raw = row
          subscription.code = row.invoiceNumber
          subscription.accounts = row.accounts
          subscription.activeSince = dateTimeNow()
          subscription.abo.cisDo = row.issueUntil
          subscription.abo.username = row.company
          subscription.activeUntil = this.calculateActiveUntil(row.issueUntil).utc().format('YYYY-MM-DDTHH:mm:ss') + '.000Z'
          return subscription
        })
    },
    importCsv (ev) {
      const file = ev.target.files[0]
      const fieldMap = [
        { from: 'DATVYS', to: 'invoiceDate' },
        { from: 'CISFAK', to: 'invoiceNumber' },
        { from: 'CISDO', to: 'issueUntil' },
        { from: 'POCET', to: 'accounts' },
        { from: 'PRIJ', to: 'company' }
      ]
      this.$papa.parse(file, {
        header: true,
        transformHeader: function (header) {
          const map = fieldMap.find(value => header === value.from)
          return map.to
        },
        skipEmptyLines: 'greedy',
        complete: function (results) {
          results.errors.forEach(function (error) {
            NotifyService.setErrorMessage(`Chyba: ${error.message}. Riadok: ${error.row}`)
            console.log(error)
          })
          const validRows = this.filterInvalidRows(results.data)
          this.subscriptions = this.mapCsvToSubscription(validRows)
        }.bind(this)
      })
    },
    async importSubscriptions () {
      for (const subscription of this.subscriptions) {
        if (subscription.activeUntil !== '' && subscription.accounts > 0 && subscription.code !== '') {
          await this.$store.dispatch('eshopSubscription/fetchOneByCode', subscription.code)
            .then(async () => {
              const oldSubscription = this.$store.getters['eshopSubscription/detailByCode'](subscription.code)
              if (oldSubscription === null) {
                await this.createSubscription(subscription)
              } else {
                oldSubscription.accounts = subscription.accounts
                oldSubscription.activeSince = subscription.activeSince
                oldSubscription.activeUntil = subscription.activeUntil
                await this.updateSubscription(oldSubscription)
              }
            })
            .catch(error => console.log(error))
        }
      }
    },
    updateSubscription (subscription) {
      this.$store.dispatch('eshopSubscription/update', subscription)
        .then(() => {
          if (this.$store.getters['eshopSubscription/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('eshopSubscription.message.updated_record'))
          } else {
            NotifyService.setErrorMessage(this.$store.getters['eshopSubscription/error'])
          }
        })
        .catch(error => console.log(error))
    },
    createSubscription (subscription) {
      this.$store.dispatch('eshopSubscription/create', this.prepareSubscriptionRequest(subscription))
        .then(() => {
          if (this.$store.getters['eshopSubscription/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('eshopSubscription.message.created_record'))
          } else {
            NotifyService.setErrorMessage(this.$store.getters['eshopSubscription/error'])
          }
        })
        .catch(error => console.log(error))
    },
    prepareSubscriptionRequest (subscription) {
      delete subscription.modifiedBy
      delete subscription.modifiedAt
      delete subscription.createdBy
      delete subscription.createdAt
      delete subscription.expirationAt
      delete subscription.payment
      delete subscription.raw
      delete subscription.subscribers
      delete subscription.activeDays
      return subscription
    },
    calculateActiveUntil (numberPlusYear) {
      const regex = /(?<number>[0-9]{2})(?<year>[0-9]{4})/
      const found = numberPlusYear.match(regex)
      const number = parseInt(found.groups.number)
      const year = parseInt(found.groups.year)

      function getDateOfWeek (issue, year) {
        // dvojvydania asi nie su nutne riesit, kedze sa pridava rezerva, ale zachova to povodny harmonogram
        const doubleIssues = [
          { issue: 30, realIssueRelease: 29 },
          { issue: 51, realIssueRelease: 50 }
        ]
        issue = doubleIssues.find(value => value.issue === issue)?.realIssueRelease ?? issue
        const days = issue * 7 + 1
        return moment(new Date(year, 0, days))
      }

      // standardne je Trend tyzdenik a 2x do roka su dvojvydania na 2 tyzdne, toto pokryje aj ad-hoc zmenu/pridanie 2 vydania
      return getDateOfWeek(number, year).add(14, 'days')
    }
  }
}
</script>

<style lang="scss" scoped>
.table-scrollbar {
  position: relative;
  height: 400px;
  overflow: auto;
}

.table-wrapper-scroll-y {
  display: block;
}
</style>
