<template>
  <app-module-view>
    <template slot="buttons">
      <app-button-create
        v-if="isCreateButtonVisible"
        route-name="eshopSubscription_new"
      >
      </app-button-create>
      <app-button-import-subscription
        v-if="isImportButtonVisible"
        @closeModal="closeModal"
        class="m-l-5"
      />
    </template>
    <template slot="body">
      <app-subscription-filter></app-subscription-filter>
      <div class="card">
        <div class="card-body">
          <app-preloader v-if="callingAPI"></app-preloader>
          <app-data-table
            :data="subscription"
            :totalCount="totalCount"
            :page="page"
            :config="dataTableConfig"
            :hasEditPermission="isEditButtonVisible"
            @page-change="setPageAndGetRecords"
          >
          </app-data-table>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../ModuleView'
import { mapState } from 'vuex'
import config from '../../config'
import Preloader from '../preloader/Preloader'
import SubscriptionFilter from './SubscriptionFilter'
import DataTable from '../shared/DataTable'
import SubscriptionTypeMixin from '../mixins/valueObject/eshop/SubscriptionTypeMixin'
import ButtonCreate from '../shared/ButtonCreate'
import StoreService from '../../services/eshop/StoreService'
import ButtonImportSubscription from '../eshop/ButtonImportSubscription'
import PermissionService from '@/services/PermissionService'

export default {
  name: 'SubscriptionList',
  mixins: [SubscriptionTypeMixin],
  data () {
    return {
      page: 1,
      dataTableConfig: {
        fields: {
          id: this.$t('eshopSubscription.id'),
          code: this.$t('eshopSubscription.code'),
          digitalServices: this.$t('eshopSubscription.digitalServices'),
          activated: this.$t('eshopSubscription.activated'),
          accountsExport: this.$t('eshopSubscription.accounts'),
          'setting.marketing': this.$t('eshopSubscription.setting.marketing'),
          'setting.admin': this.$t('eshopSubscription.setting.admin'),
          type: this.$t('eshopSubscription.type'),
          activeDays: this.$t('eshopSubscription.activeDays'),
          expirationAt: this.$t('eshopSubscription.expiration_at'),
          activeSince: this.$t('eshopSubscription.activeSince'),
          activeUntil: this.$t('eshopSubscription.activeUntil'),
          createdAt: this.$t('eshopSubscription.created_at')
        },
        actions: {
          edit: 'eshopSubscription_edit'
        },
        render: {
          type: function (data) {
            return this.subscriptionTypeById(data).title
          }.bind(this),
          digitalServices: function (data) {
            const output = data.map(
              digitalService => this.$store.getters['eshopDigitalService/digitalServiceById'](digitalService).name
            )
            return output.toString()
          }.bind(this),
          'setting.marketing': function (data) {
            if (data) {
              return '<i class="fas fa-check-circle"></i>'
            }
          },
          'setting.admin': function (data) {
            if (data) {
              return '<i class="fas fa-check-circle"></i>'
            }
          },
          activated: function (data) {
            if (data) {
              return '<i class="fas fa-check-circle"></i>'
            }
          },
          activeDays: function (data) {
            if (!data) {
              return ''
            }
            return data
          }
        }
      },
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.ESHOP_SUBSCRIPTION_PERMISSIONS
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    isCreateButtonVisible () {
      return this.hasPermission(this.requiredPermissions.createButton)
    },
    isEditButtonVisible () {
      return this.hasPermission(this.requiredPermissions.editButton)
    },
    isImportButtonVisible () {
      return this.hasPermission(this.requiredPermissions.importButton)
    },
    subscription () {
      return this.$store.getters['eshopSubscription/list']
    },
    totalCount () {
      return this.$store.getters['eshopSubscription/totalCount']
    },
    pageCount () {
      return Math.ceil(this.$store.getters['eshopSubscription/totalCount'] / config.defaults.list.limit)
    }
  },
  components: {
    appModuleView: ModuleView,
    appPreloader: Preloader,
    appSubscriptionFilter: SubscriptionFilter,
    appDataTable: DataTable,
    appButtonCreate: ButtonCreate,
    appButtonImportSubscription: ButtonImportSubscription
  },
  methods: {
    hasPermission (permission) {
      return this.$store.getters['user/hasPermission'](permission)
    },
    getSubscription () {
      this.$store.dispatch('eshopSubscription/fetch')
    },
    setPageAndGetRecords (page) {
      this.page = page
      this.$store.commit('eshopSubscription/setPage', page)
      this.getSubscription()
    },
    closeModal () {
      this.$store.dispatch('eshopSubscription/fetch')
    }
  },
  mounted () {
    this.page = this.$store.getters['eshopSubscription/page']
    this.getSubscription()
    StoreService.fetchAll()
  }
}
</script>

<style lang="scss">

</style>
